import { SOCIAL_MEDIA_LINKS } from 'utils/socialMediaConstants';

const SOCIAL_LINKS = [
  {
    title: 'Website',
    value: 'goodingco.com',
    link: SOCIAL_MEDIA_LINKS.website,
  },
  {
    title: 'Facebook',
    value: 'www.facebook.com/GoodingandCompany',
    link: SOCIAL_MEDIA_LINKS.facebook,
  },
  {
    title: 'Twitter',
    value: '@goodingandco',
    link: SOCIAL_MEDIA_LINKS.twitter,
  },
  {
    title: 'Instagram',
    value: '@goodingandcompany',
    link: SOCIAL_MEDIA_LINKS.instagram,
  },
  {
    title: 'YouTube',
    value: 'www.youtube.com/GoodingandCompany',
    link: SOCIAL_MEDIA_LINKS.youtube,
  },
];

export { SOCIAL_LINKS };
