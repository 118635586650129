import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import RichText from 'features/RichText';
import { SOCIAL_LINKS } from 'src/data/media';
import Share from 'components/Share';
import styles from './mediaDetails.module.scss';
import { generateContentfulAssetURL } from 'utils/image';

const MediaDetails = props => {
  const article = props.data.contentfulPost;
  const seoImage = {
    url: generateContentfulAssetURL(article.image?.file?.url),
    width: article?.image?.file?.details?.image?.width,
    height: article?.image?.file?.details?.image?.height,
  };

  return (
    <Layout>
      <SEO title={article?.title ?? 'Article'} image={seoImage} />
      <div>
        <div className={styles.article}>
          <div className={`${styles.articleHeader} pt-4`}>
            <h2 className={styles.pressPostTitle}>Press Release</h2>
            <h1>{article.title}</h1>
            <h2 className={`letterSpace ${styles.subTitle}`}>
              {article.subtitle}
            </h2>
            <Share type="app-transparent-blue-button" />
          </div>
          <div className={styles.articleText}>
            <RichText json={article?.body?.json} />
          </div>
          <div className={styles.link}>
            {SOCIAL_LINKS.map((row, index) => (
              <p key={`socialLink${index}`}>
                {row.title}:{' '}
                <a
                  href={row.link}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.webLink}
                >
                  {row.value}
                </a>
              </p>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MediaDetails;

export const query = graphql`
  query getPost($id: String) {
    contentfulPost(contentful_id: { eq: $id }) {
      slug
      subtitle
      title
      body {
        json
      }
      image {
        fluid(maxWidth: 1800, maxHeight: 620, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
    }
  }
`;
